import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENV_CONFIG, EnvConfig } from '../configuration';
import { KeyPrefix } from './shared/abstracts/storage-base';
import { AuthenticationWithLoginGuard } from './auth/guards/authentication-with-login.guard';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { XsrfInterceptor } from './shared/interceptors/xsrf.interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { MaterialModule } from './shared/material.module';
@NgModule({ declarations: [AppComponent, LoadingPageComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: true
            }
        })], providers: [
        AuthenticationWithLoginGuard,
        {
            provide: KeyPrefix,
            useFactory: (envConfig: EnvConfig) => envConfig.appConfig.storageKeyPrefix,
            deps: [ENV_CONFIG]
        },
        { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
