import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {
  isSmallScreen: boolean;
  // constructor() { }
  @HostListener('window:resize', []) updateScreenSize() {
    if (window.innerWidth > 983) {
      this.isSmallScreen = false;
    }
    if (window.innerWidth < 984) {
      this.isSmallScreen = true;
    }
  }
  ngOnInit(): void {
    this.updateScreenSize();
  }
}
