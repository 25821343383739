<div style="height: 100%; background: #8f221c">
  <mat-grid-list *ngIf="isSmallScreen" cols="5" rowHeight="7.5rem">
    <mat-grid-tile class="shield-tile" colspan="5" rowspan="1">
      <img
        style="height: 200px; margin-right: 60%; margin-top: 120px"
        src="https://connect.wustl.edu/login/images/shield.svg"
        alt="WUSTL"
      />
    </mat-grid-tile>
    <mat-grid-tile class="top-center" style="background: #8f221c" colspan="5" rowspan="8">
      <div style="padding-top: 10vh">
        <div style="display: flex; justify-content: center">
          <img style="height: 8vw" src="assets/images/washu-single-line-logo-white.png" alt="WUSTL" />
        </div>
        <div style="display: flex; justify-content: center; padding: 20%">
          <span style="padding-right: 50px" class="spinner"></span>
        </div>
        <div style="display: flex; justify-content: center">
          <span style="color: white; font-size: x-large; text-align: center">Welcome To RADAR</span>
        </div>
        <div style="display: flex; justify-content: center">
          <span style="color: white; font-size: x-large; text-align: center"
            >Please wait while we get everything ready.</span
          >
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list *ngIf="!isSmallScreen" cols="5" rowHeight="100%">
    <!-- Welcome To Radar Message -->
    <mat-grid-tile style="background: #8f221c; display: block" colspan="3" rowspan="1">
      <div>
        <div>
          <img style="height: 5vw" src="assets/images/washu-single-line-logo-white.png" alt="WUSTL" />
        </div>
        <div style="display: flex; justify-content: center; padding: 20%">
          <span class="spinner"></span>
        </div>
        <div style="display: flex; justify-content: center">
          <span style="color: white; font-size: x-large">Welcome To RADAR</span>
        </div>
        <div style="display: flex; justify-content: center">
          <span style="color: white; font-size: x-large">Please wait while we get everything ready.</span>
        </div>
      </div>
    </mat-grid-tile>

    <!-- Grey Shield background -->
    <mat-grid-tile class="shield-tile" colspan="2" rowspan="1">
      <img style="margin-left: 80%" src="https://connect.wustl.edu/login/images/shield.svg" alt="WUSTL" />
    </mat-grid-tile>
  </mat-grid-list>
</div>
