import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpXsrfTokenExtractor } from '@angular/common/http';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
  constructor(private xsrfTokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.toLowerCase().includes('/public')) {
      return next.handle(req);
    }

    // load token
    const xsrfToken = this.xsrfTokenExtractor.getToken();

    if (xsrfToken != null) {
      // create a copy of the request and
      // append the XSRF token to the headers list
      const request = req.clone({
        withCredentials: true,
        headers: req.headers.set('X-XSRF-TOKEN', xsrfToken)
      });

      return next.handle(request);
    } else {
      const request = req.clone({
        withCredentials: true
      });
      return next.handle(request);
    }
  }
}
