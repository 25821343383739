import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationWithLoginGuard } from './auth/guards/authentication-with-login.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { NotAuthorizedComponent } from './auth/components/not-authorized/not-authorized.component';

const routes: Routes = [
  {
    path: 'noaccess',
    component: NotAuthorizedComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule)
  },
  {
    canActivate: [AuthenticationWithLoginGuard, AuthGuard],
    path: '',
    loadChildren: () => import('./layout/layout.module').then((module) => module.LayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
