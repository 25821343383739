import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'radar-dashboard';
  isLoading: boolean;

  constructor(private authService: AuthService) {
    this.isLoading = true;
    this.authService.runInitialLoginSequence().then(() => {
      this.isLoading = false;
    });
  }
}
