import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.user$.pipe(
      filter((user) => !!user),
      map((user) => {
        if (!user.isActive) {
          this.router.navigate(['auth/not-authorized']);
        }
        return !!user || this.router.parseUrl(`unauthorized?redirect=${state.url}`);
      })
    );
  }
}
